// List: https://docs.google.com/spreadsheets/d/10H6CRoCg4ZltW2sHnaDItYs4S0hc4qELMrp_oB4ieqg/edit?gid=0#gid=0

import { computed } from 'vue';
import { useUserStore } from '@/helpers/composables/useStores';

const { isLoggedIn } = useUserStore();

const excludedPackages = [
	352, 1957, 569, 3, 562, 1771, 192, 33, 349, 1764, 35, 1993, 40, 456, 61, 58, 224, 1862, 641, 2237, 2149,
];

export const EXCLUDED_PACKAGES = computed(() => {
	// Space for logic e.g. country exceptions

	// If user is not logged in, exclude Crunchyroll package
	if (!isLoggedIn.value) {
		return [...excludedPackages, 283];
	}

	return excludedPackages;
});
