import { computed, type UnwrapRef } from 'vue';
import { toValue, useMounted, type MaybeRefOrGetter } from '@vueuse/core';

import { useLanguageStore } from '@/helpers/composables/useStores';

import { getRawCatalogue, type CatalogueItem } from '@/helpers/promotion-helper';

import type { TitleDetail } from '@/interfaces/title-details-graphql';
import type { TitleOfferFragment } from '@/components/buybox/graphql/fragments/Offer.fragment';

interface UsePromotionOptions {
	offers: MaybeRefOrGetter<TitleOfferFragment[]>;
	title: MaybeRefOrGetter<TitleDetail>;
}

export type UsePromotion = UnwrapRef<ReturnType<typeof usePromotion>>;

/** Only until we get the remaining translations. */
export const allowedPromotionLanguages = ['es', 'en', 'de', 'fr', 'pt', 'it'];

export function usePromotion({ offers, title }: UsePromotionOptions) {
	const isMounted = useMounted();
	const { country } = useLanguageStore();

	/** The full list of available promotions along with an `include` flag. */
	const promotionCatalogue = computed(() => {
		// We need the insertion order for offer priority
		return new Map<number, CatalogueItem>(
			getRawCatalogue({ country, title })
				// client side inclusion filtering to avoid hydration issues with geo location
				.filter(item => isMounted.value && item.include)
				.map(item => [item.packageId, item])
		);
	});

	/** The highest priority title offer from the promotion catalogue based on the catalogue's priorities. */
	const bestTitleOffer = computed(() => {
		const availableOffers = toValue(offers);

		const bestTitleOffer = Array.from(promotionCatalogue.value.keys())
			.map(id => availableOffers.find(offer => offer.package.packageId === id))
			.find(offer => offer != null);

		return bestTitleOffer ?? null;
	});

	/** If the title has an offer from a provider in the promotion catalogue. */
	const inPromotionCatalogue = computed(() => bestTitleOffer.value !== null);

	/**
	 * The promotion that matches the highest priority offer from the catalogue.
	 * If no match is found, returns the fallback promotion; currently Apple TV+.
	 */
	const catalogueItem = computed(() => {
		if (bestTitleOffer.value) {
			return promotionCatalogue.value.get(bestTitleOffer.value.package.packageId) ?? null;
		}

		const { done, value } = promotionCatalogue.value.values().next();
		if (!done) return value;

		return null;
	});

	/** The top offer from the promotion catalogue. */
	const promotionOffer = computed(() => {
		if (catalogueItem.value) {
			return catalogueItem.value.offer(bestTitleOffer, country, inPromotionCatalogue);
		}

		return bestTitleOffer.value;
	});

	return {
		/** All the promotions that should be included, sorted by priority. */
		promotionCatalogue,

		/** If the title has an offer from a provider in the promotion catalogue. */
		inPromotionCatalogue,

		/** The highest priority title offer from the promotion catalogue based on the catalogue's priorities. */
		bestTitleOffer,

		/**
		 * The promotion that matches the highest priority offer from the catalogue.
		 * If no match is found, returns the fallback promotion; currently Apple TV+.
		 */
		catalogueItem,

		/** The top offer from the promotion catalogue. */
		promotionOffer,
	};
}
