import { ShowPageExpV3Config } from './types';

export const showPageExpV3Config: ShowPageExpV3Config = {
	US: {
		350: {
			control: {
				ct_atds: 'showexp_at_control_us',
				ct_natds: 'showexp_nat_control_us',
				ct_reg: 'showexp_reg_control_us',
			},
			variant_1: {
				ct_atds: 'showexp_at_v1_us',
				ct_natds: 'showexp_nat_v1_us',
				ct_reg: 'showexp_reg_v1_us',
			},
			variant_2: {
				ct_atds: 'showexp_at_v2_us',
				ct_natds: 'showexp_nat_v2_us',
				ct_reg: 'showexp_reg_v2_us',
			},
			variant_3: {
				ct_atds: 'showexp_at_v3_us',
				ct_natds: 'showexp_nat_v3_us',
				ct_reg: 'showexp_reg_v3_us',
			},
			variant_4: {
				ct_atds: 'showexp_at_v4_us',
				ct_natds: 'showexp_nat_v4_us',
				ct_reg: 'showexp_reg_v4_us',
			},
			variant_5: {
				ct_atds: 'showexp_at_v5_us',
				ct_natds: 'showexp_nat_v5_us',
				ct_reg: 'showexp_reg_v5_us',
			},
		},
		9: {
			control: 'justus-showexp-cont-20',
			variant_1: 'justus-showexp-v1-20',
			variant_2: 'justus-showexp-v2-20',
			variant_3: 'justus-showexp-v3-20',
			variant_4: 'justus-showexp-v4-20',
			variant_5: 'justus-showexp-v5-20',
		},
		2100: {
			control: 'justus-showexp-cont-20',
			variant_1: 'justus-showexp-v1-20',
			variant_2: 'justus-showexp-v2-20',
			variant_3: 'justus-showexp-v3-20',
			variant_4: 'justus-showexp-v4-20',
			variant_5: 'justus-showexp-v5-20',
		},
		15: {
			control: 'showexp_control',
			variant_1: 'showexp_v1',
			variant_2: 'showexp_v2',
			variant_3: 'showexp_v3',
			variant_4: 'showexp_v4',
			variant_5: 'showexp_v5',
		},
	},
	GB: {
		350: {
			control: {
				ct_atds: 'showexp_at_control_gb',
				ct_natds: 'showexp_nat_control_gb',
				ct_reg: 'showexp_reg_control_gb',
			},
			variant_1: {
				ct_atds: 'showexp_at_v1_gb',
				ct_natds: 'showexp_nat_v1_gb',
				ct_reg: 'showexp_reg_v1_gb',
			},
			variant_2: {
				ct_atds: 'showexp_at_v2_gb',
				ct_natds: 'showexp_nat_v2_gb',
				ct_reg: 'showexp_reg_v2_gb',
			},
			variant_3: {
				ct_atds: 'showexp_at_v3_gb',
				ct_natds: 'showexp_nat_v3_gb',
				ct_reg: 'showexp_reg_v3_gb',
			},
			variant_4: {
				ct_atds: 'showexp_at_v4_gb',
				ct_natds: 'showexp_nat_v4_gb',
				ct_reg: 'showexp_reg_v4_gb',
			},
			variant_5: {
				ct_atds: 'showexp_at_v5_gb',
				ct_natds: 'showexp_nat_v5_gb',
				ct_reg: 'showexp_reg_v5_gb',
			},
		},
		9: {
			control: 'justgb-showexp-cont-21',
			variant_1: 'justgb-showexp-v1-21',
			variant_2: 'justgb-showexp-v2-21',
			variant_3: 'justgb-showexp-v3-21',
			variant_4: 'justgb-showexp-v4-21',
			variant_5: 'justgb-showexp-v5-21',
		},

		2100: {
			control: 'justgb-showexp-cont-21',
			variant_1: 'justgb-showexp-v1-21',
			variant_2: 'justgb-showexp-v2-21',
			variant_3: 'justgb-showexp-v3-21',
			variant_4: 'justgb-showexp-v4-21',
			variant_5: 'justgb-showexp-v5-21',
		},
	},
	IN: {
		350: {
			control: {
				ct_atds: 'showexp_at_control_in',
				ct_natds: 'showexp_nat_control_in',
				ct_reg: 'showexp_reg_control_in',
			},
			variant_1: {
				ct_atds: 'showexp_at_v1_in',
				ct_natds: 'showexp_nat_v1_in',
				ct_reg: 'showexp_reg_v1_in',
			},
			variant_2: {
				ct_atds: 'showexp_at_v2_in',
				ct_natds: 'showexp_nat_v2_in',
				ct_reg: 'showexp_reg_v2_in',
			},
			variant_3: {
				ct_atds: 'showexp_at_v3_in',
				ct_natds: 'showexp_nat_v3_in',
				ct_reg: 'showexp_reg_v3_in',
			},
			variant_4: {
				ct_atds: 'showexp_at_v4_in',
				ct_natds: 'showexp_nat_v4_in',
				ct_reg: 'showexp_reg_v4_in',
			},
			variant_5: {
				ct_atds: 'showexp_at_v5_in',
				ct_natds: 'showexp_nat_v5_in',
				ct_reg: 'showexp_reg_v5_in',
			},
		},
		119: {
			control: 'showexp-control',
			variant_1: 'showexp-v1',
			variant_2: 'showexp-v2',
			variant_3: 'showexp-v3',
			variant_4: 'showexp-v4',
			variant_5: 'showexp-v5',
		},
	},
};
