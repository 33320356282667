import { render, staticRenderFns } from "./BuyBoxOffer.vue?vue&type=template&id=3c7bc032&scoped=true"
import script from "./BuyBoxOffer.vue?vue&type=script&setup=true&lang=ts"
export * from "./BuyBoxOffer.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./BuyBoxOffer.vue?vue&type=style&index=0&id=3c7bc032&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c7bc032",
  null
  
)

export default component.exports