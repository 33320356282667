export type QualityTVPlacements = 'TitlePage' | 'SearchPage' | 'HomePage' | 'SearchBox';

export const qualityTvConfig = [
	{
		countries: ['FR', 'PT', 'SG', 'SA'],
		itsct: 'justwatch_tv_11',
	},
	{
		countries: ['MX', 'DE', 'AE'],
		itsct: 'justwatch_tv_10',
	},
	{
		countries: ['IE', 'AT', 'CH', 'NL', 'ES', 'ZA', 'CR', 'HN'],
		itsct: 'justwatch_tv_4',
	},
	{
		countries: ['AU', 'CA', 'PH', 'ID', 'TH', 'MY'],
		itsct: 'justwatch_tv_13',
	},
];

export const appleDefaultParams = {
	itsct: 'justwatch_tv_6',
};

export const appleQualityTvCountries = qualityTvConfig.flatMap(config => config.countries);

export const compositionIDSuffixByPlacement: Record<QualityTVPlacements, string> = {
	TitlePage: '',
	SearchPage: '-sp',
	HomePage: '-hm',
	SearchBox: '-sb',
};

export function getItsctParam(country: string, placement: QualityTVPlacements): string {
	switch (placement) {
		case 'TitlePage':
			const config = qualityTvConfig.find(config => config.countries.includes(country));
			return config?.itsct ?? appleDefaultParams.itsct;
		case 'HomePage':
			return 'justwatch_tv_2';
		case 'SearchBox':
			return 'justwatch_tv_8';
		case 'SearchPage':
			return 'justwatch_tv_9';
		default:
			return appleDefaultParams.itsct;
	}
}
