import { ClickoutUrl } from '@/helpers/clickout-helper';
import { NewBuyBoxTVShowExpV3Variants } from '.';
import { showPageExpV3Config } from './data';

export function showPageClickoutUrlRegular(
	packageId: number,
	country: 'US' | 'IN' | 'GB',
	url: ClickoutUrl,
	showPageVariant: NewBuyBoxTVShowExpV3Variants
) {
	if (packageId === 350) {
		return url.set('uct_ct', showPageExpV3Config[country][packageId][showPageVariant].ct_reg);
	}
	if ((packageId === 2100 || packageId === 9) && country !== 'IN') {
		return url.setOnOffer('tag', showPageExpV3Config[country][packageId][showPageVariant]);
	}
	if (packageId === 119 && country === 'IN') {
		return url.set('uct_ct', showPageExpV3Config[country][packageId][showPageVariant]);
	}
	if (packageId === 15 && country === 'US') {
		return url.setOnOffer('sub2', showPageExpV3Config[country][packageId][showPageVariant]);
	}
	return url;
}

export function showPageClickoutUrlPromotion(
	packageId: number,
	country: 'US' | 'IN' | 'GB',
	url: ClickoutUrl,
	showPageVariant: NewBuyBoxTVShowExpV3Variants,
	isAppleTVPlusTitle: boolean
) {
	if (packageId === 350) {
		return url.set(
			'uct_ct',
			isAppleTVPlusTitle
				? showPageExpV3Config[country][packageId][showPageVariant].ct_atds
				: showPageExpV3Config[country][packageId][showPageVariant].ct_natds
		);
	}
	if (packageId === 9 && country === 'GB') {
		return url.setOnOffer('tag', showPageExpV3Config[country][packageId][showPageVariant]);
	}
	if (packageId === 15 && country === 'US') {
		return url.setOnOffer('sub2', showPageExpV3Config[country][packageId][showPageVariant]);
	}
	return url;
}
