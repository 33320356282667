import { toValue, type MaybeRefOrGetter } from '@vueuse/core';

import type { TitleDetail } from '@/interfaces/title-details-graphql';
import { TitleOfferFragment } from '@/components/buybox/graphql/fragments/Offer.fragment';

import { OfferPresentationType } from '@/interfaces/titles';
import { ClassicUrl } from '@/helpers/clickout-helper';
import { getUserLocationCountryCode } from '@/helpers/geo-location-helper';

//////////////
//* CONFIG *//
//////////////

type PriorityCheck = (args: MaybeRefCountryAndTitle) => 0 | 1 | 2 | 3;
type InclusionCheck = (args: MaybeRefCountryAndTitle) => boolean;

/**
 * Configuration for a Provider Promotion.
 *
 * All the optional fields will be handled by `toCatalogueItem` with defaults.
 * Set them in the config to override.
 */
interface CatalogueItemConfig {
	packageId: number;
	clearName: string;
	technicalName: string;
	icon: string;

	/** List of countries where this promotion can appear. Empty array means *every* country. */
	countries?: ReadonlyArray<string>;
	/** List of countries where the promotion should *not* appear. */
	excludeCountries?: ReadonlyArray<string>;
	/** List of countries where the promotion should *not* appear based on the visitor's geo location. */
	geoblockCountries?: ReadonlyArray<string>;

	/**
	 * If the promotion appears without a catalogue title, it will use one of
	 * the given fallback URLs. First it tries to find a country specific fallback
	 * otherwise it uses the `DEFAULT` one.
	 */
	fallbacks?: {
		DEFAULT: string;
		[countryCode: string]: string;
	};
	offer?: (
		offer: MaybeRefOrGetter<TitleOfferFragment | null>,
		country: MaybeRefOrGetter<string>
	) => TitleOfferFragment;

	/**
	 * By default, priority is decided by the order in `PromotionsConfig`.
	 * First item is highest, then second, and so on.
	 *
	 * This property lets you override the order based on the title and country.
	 */
	priority?: PriorityCheck;
	include?: InclusionCheck;

	/** Optional function to rewrite the offer URL. */
	linkRewrite?: (link: string | null | undefined, country: string) => string | null;
}

/** Identifies dummy offers used when there wasn't a title offer available. */
export const FALLBACK_PROMOTION_OFFER = 'PROMOTION_OFFER' as const;

// prettier-ignore
export const geoBlockedCountriesFreeTrialAppleFallback = [
	 'BG', 'BM', 'BY', 'EE', 'EG','FI', 'FJ', 'JO', 'KH', 'KN', 'KR',
	'KY', 'LA', 'LB', 'LK', 'LV', 'MD', 'MO', 'MT', 'MZ', 'NE', 'NI', 'OM', 'PA',
	'QA', 'RU', 'SK', 'SZ', 'TJ', 'TT', 'TW', 'UA', 'VN',
];

// const PrimeVideoPrioCountries = ['US', 'ES', 'BR', 'IT', 'DE', 'MX'];

/** Catalogue of Promotions, edit or add new ones here. */
const PromotionsConfig: ReadonlyArray<CatalogueItemConfig> = [
	{
		/* Apple TV+ */
		packageId: 350,
		clearName: 'Apple TV+',
		technicalName: 'appletvplus',
		icon: '/icon/152862153/s100/appletvplus.{format}',
		// prettier-ignore
		excludeCountries: [
			'BO', 'GH', 'MU', 'PY', 'ZW',
			...geoBlockedCountriesFreeTrialAppleFallback,
		],
		fallbacks: {
			DEFAULT: 'https://tv.apple.com/channel/tvs.sbd.4000?at=1000l3V2&ct=free_trial&itscg=30200',
		},
		include: () => true,
	},
	{
		/* Amazon Prime Video */
		packageId: 9,
		clearName: 'Amazon Prime Video',
		technicalName: 'amazonprime',
		icon: '/icon/52449539/s100/amazonprime.{format}',
		countries: ['GB', 'DE'],
		fallbacks: {
			DEFAULT: 'https://www.amazon.com/gp/video/offers?tag=justus1ktp-20',
			DE: 'https://www.amazon.de/gp/video/primesignup?tag=movie0c6-21',
			// US: 'https://www.amazon.com/gp/video/offers?tag=justus1ktp-20',
		},
		include({ title }) {
			const { offers = [], content } = toValue(title);

			const hasOwnOffer = offers.some(offer => offer.package.packageId === this.packageId);
			const hasCrunchyRollOffer = offers.some(offer => offer.package.packageId === 283);
			const hasNoHuluOffer = offers.every(offer => offer.package.packageId !== 15);
			const isAnimation = content.genres.some(genre => genre.shortName === 'ani');

			return hasOwnOffer || (hasCrunchyRollOffer && isAnimation) || (hasNoHuluOffer && isAnimation);
		},
		linkRewrite: amazonLinkRewrite,
	},
	{
		/* Amazon Prime Video */
		packageId: 119,
		clearName: 'Amazon Prime Video',
		technicalName: 'amazonprimevideo',
		icon: '/icon/52449539/s100/amazonprime.{format}',
		countries: ['BR', 'ES', 'IT', 'FR', 'PT', 'CO', 'AR', 'DK', 'CR', 'PY', 'BO', 'MX'],
		fallbacks: {
			DEFAULT: 'http://primevideo-eu.pxf.io/B095Wy',
			MX: 'http://primevideo-row.pxf.io/WqzOjA',
		},
		include({ title }) {
			const { offers = [], content } = toValue(title);

			const hasOwnOffer = offers.some(offer => offer.package.packageId === this.packageId);
			const hasCrunchyRollOffer = offers.some(offer => offer.package.packageId === 283);
			const hasNoHuluOffer = offers.every(offer => offer.package.packageId !== 15);
			const isAnimation = content.genres.some(genre => genre.shortName === 'ani');

			return hasOwnOffer || (hasCrunchyRollOffer && isAnimation) || (hasNoHuluOffer && isAnimation);
		},
		linkRewrite: amazonLinkRewrite,
	},
	{
		/* Paramount+ Amazon Channel */
		packageId: 582,
		clearName: 'Paramount+ Amazon Channel',
		technicalName: 'amazonparamountplus',
		icon: '/icon/246478651/s100/amazonparamountplus.{format}',
		countries: ['DE', 'GB', 'IT', 'FR'],
		linkRewrite: amazonLinkRewrite,
	},
	{
		/* MGM Plus Amazon Channel */
		packageId: 588,
		clearName: 'MGM Plus Amazon Channel',
		technicalName: 'amazonmgmplus',
		icon: '/icon/302467404/s100/amazonepix.{format}',
		countries: ['DE', 'FR', 'IT', 'GB', 'ES'],
		linkRewrite: amazonLinkRewrite,
	},
	{
		/* FlixOlé Amazon Channel */
		packageId: 684,
		clearName: 'FlixOlé Amazon Channel',
		technicalName: 'amazonflixole',
		icon: '/icon/259252096/s100/amazonflixole.{format}',
		countries: ['ES'],
		linkRewrite: amazonLinkRewrite,
	},
	{
		/* Hulu */
		packageId: 15,
		clearName: 'Hulu',
		technicalName: 'hulu',
		icon: '/icon/116305230/s100/hulu.{format}',
		countries: ['US'],
		fallbacks: {
			DEFAULT: 'https://www.kmtrak.com/23H7P9/3HWS9F7/?sub1=free_trial',
		},
	},
];

/** Get the configured catalogue of provider promotions. */
export const getRawCatalogue = (args: MaybeRefCountryAndTitle) =>
	PromotionsConfig.map(config => toCatalogueItem(config, args)).sort((a, b) => b.priority - a.priority);

export interface CatalogueItem {
	packageId: number;
	clearName: string;
	technicalName: string;
	offer: (
		offer: MaybeRefOrGetter<TitleOfferFragment | null>,
		country: MaybeRefOrGetter<string>,
		inCatalogue: MaybeRefOrGetter<boolean>
	) => TitleOfferFragment;
	priority: ReturnType<PriorityCheck>;
	include: ReturnType<InclusionCheck>;
}

/////////////
//* UTILS *//
/////////////

type MaybeRefCountryAndTitle = { country: MaybeRefOrGetter<string>; title: MaybeRefOrGetter<TitleDetail> };

/** Adds default checks and config fields to the base config defined in `PromotionsConfig`. */
function toCatalogueItem(config: CatalogueItemConfig, args: MaybeRefCountryAndTitle): CatalogueItem {
	const { priority, packageId, clearName, technicalName } = config;

	const country = toValue(args.country);
	const title = toValue(args.title);

	return {
		packageId,
		clearName,
		technicalName,

		offer: (
			offer: MaybeRefOrGetter<TitleOfferFragment | null>,
			country: MaybeRefOrGetter<string>,
			inCatalogue: MaybeRefOrGetter<boolean>
		) => withFallback(toValue(offer), toValue(country), toValue(inCatalogue), config),

		priority: !title || !priority ? 0 : priority.call(config, { country, title }),

		include: inclusionCheck(config, toValue(country), toValue(title)),
	};
}

/** Check whether a promotion config should be included in the catalogue. */
function inclusionCheck(config: CatalogueItemConfig, country: string, title: TitleDetail) {
	const { countries = [], excludeCountries, geoblockCountries, include } = config;

	// an empty country array means "Include in Every Country"
	if (countries.length > 0 && !new Set(countries).has(country)) return false;

	if (excludeCountries && new Set(excludeCountries).has(country)) return false;

	if (geoblockCountries) {
		const geoblockedCountries = new Set(geoblockCountries);
		if (geoblockedCountries.has(country)) return false;

		const userCountry = getUserLocationCountryCode();
		if (userCountry && geoblockedCountries.has(userCountry)) return false;
	}

	if (!title) return false;

	if (include) return include.call(config, { country, title });

	return true;
}

/** Returns the title offer if there is one otherwise uses a dummy fallback with the config's URLs. */
function withFallback(
	offer: TitleOfferFragment | null,
	country: string,
	inCatalogue: boolean,
	{ clearName, packageId, icon, fallbacks, linkRewrite }: CatalogueItemConfig
) {
	if (offer == null) {
		// Minimum properties needed for tracking
		return {
			id: FALLBACK_PROMOTION_OFFER,
			monetizationType: OfferPresentationType.FREE,
			presentationType: 'hd',
			package: { clearName, packageId },
			standardWebURL: fallbacks?.[country] ?? fallbacks?.DEFAULT ?? '',
		} as unknown as TitleOfferFragment;
	}

	let offerCopy = offer;

	// The actual Apple TV+ package has the clearName "Apple TV Plus"
	offerCopy.package.clearName = clearName;
	offerCopy.package.icon = icon;

	if (linkRewrite && inCatalogue) {
		// Original offer object is reactive, modifying it can cause infinite render loops
		offerCopy = structuredClone(offer);
		offerCopy.standardWebURL = linkRewrite(offer.standardWebURL, country);
	}

	return offerCopy;
}

/////////////////////
//* LINK REWRITES *//
/////////////////////
const amazonTagByCountry: Record<string, string> = {
	DE: 'movie0c6-21',
	ES: 'just0a7-21',
	FR: 'just03b-21',
	GB: 'just016-21',
	IT: 'justwatch07-21',
};

// const amazonPrefixByCountry: Record<string, string> = {
// 	AR: 'https://primevideo-row.pxf.io/XYr3NX',
// 	CO: 'https://primevideo-row.pxf.io/XYr3NX',
// 	DK: 'https://primevideo-eu.pxf.io/1rKvQm',
// 	PT: 'https://primevideo-eu.pxf.io/1rKvQm',
// };

export function amazonLinkRewrite(link: string | null | undefined, country: string) {
	if (!link) return null;

	const overwrittenLink = new ClassicUrl(link)
		.set('linkCode', 'xm2')
		.set('tag', amazonTagByCountry[country])
		.toString();

	// const prefix = new ClassicUrl(amazonPrefixByCountry[country]);
	// if (!prefix.error) {
	// 	return prefix.set('u', overwrittenLink).toString();
	// }

	return overwrittenLink;
}
